import https from 'https';
import axios, { AxiosError } from 'axios';
import { signOut, getSession } from 'next-auth/react';
import getConfig from 'next/config';
import router from 'next/router';
import APP_ROUTES from 'src/common/appRoutes';
import { CHATGPT_BASE_URL } from 'src/common/constants';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { NEXT_PUBLIC_BASE_URL, NODE_ENV } = publicRuntimeConfig;

function createAxiosClient(baseURL: string, contentType: string, base64Token?: string) {
  let agent;

  if (typeof window === 'undefined' && NODE_ENV !== 'production') {
    agent = new https.Agent({
      rejectUnauthorized: false
    });
  }

  const axiosClient = axios.create({
    baseURL,
    httpsAgent: agent, // This agent prevent ERR_TLS_CERT_ALTNAME_INVALID issue when in SSR development environment,
    headers: {
      'content-type': contentType
    }
  });

  axiosClient.interceptors.request.use(
    async (config) => {
      if (typeof window === 'undefined') return config;
      const accessToken = localStorage.getItem('token');
      if (base64Token) {
        config.headers['Chart-Authorization'] = base64Token;
      }
      if (!accessToken) return config;
      config.headers.Authorization = accessToken;

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error: AxiosError) => {
      if (error.response?.status.toString() === '401' && typeof window !== 'undefined') {
        const session = await getSession();
        if (session) {
          localStorage.clear();
          signOut({ callbackUrl: APP_ROUTES.SIGN_IN });
        } else {
          router.push(APP_ROUTES.SIGN_IN);
        }
      }
      return Promise.reject(error);
    }
  );
  return axiosClient;
}

const appAxios = createAxiosClient(NEXT_PUBLIC_BASE_URL, 'application/json');
const appAxiosAsk = createAxiosClient(CHATGPT_BASE_URL, 'application/json');
const appAxiosChart = (base64Token: string) => {
  return createAxiosClient(NEXT_PUBLIC_BASE_URL, 'multipart/form-data', base64Token);
};

export { appAxios, appAxiosAsk, appAxiosChart };
